import { PlanSlug } from 'common/planSlugs'
import useWindowSize from 'components/util/useWindowSize'

export function getPlanImages(slug: PlanSlug) {
  switch (slug) {
    case PlanSlug.COLLECT:
      return require('public/images/plans/plan/collect-page-hero-desktop.png')
    case PlanSlug.FORWARD:
      return require('public/images/plans/plan/forward-page-hero-desktop.png')
    case PlanSlug.MEETINGS:
      return require('public/images/hero/homepage-meetings-plan-desktop.png')
    case PlanSlug.PHONE:
      return require('public/images/plans/plan/phone-page-hero-desktop.png')
    case PlanSlug.SCAN_LITE:
      return require('public/images/plans/plan/scan-lite-page-hero-desktop.png')
    case PlanSlug.SCAN_PRO:
      return require('public/images/plans/plan/scan-pro-page-hero-desktop.png')
  }
}

export function getPlanFeaturesImage(slug: PlanSlug) {
  const size = useWindowSize()

  switch (slug) {
    case PlanSlug.COLLECT:
      if (size.isMdOrLower) {
        return require('public/images/address/homepage-plugz-point-mobile.png')
      }
      return require('public/images/address/homepage-plugz-point-desktop.png')
    case PlanSlug.FORWARD:
      if (size.isMdOrLower) {
        return require('public/images/address/homepage-plugz-point-mobile.png')
      }
      return require('public/images/plans/forward-page-point-right-desktop.png')
    case PlanSlug.MEETINGS:
    case PlanSlug.PHONE:
    case PlanSlug.SCAN_LITE:
      if (size.isMdOrLower) {
        return require('public/images/plans/scan-lite-scanz-point-right-mobile.png')
      }
      return require('public/images/plans/scan-lite-scanz-point-right-desktop.png')
    case PlanSlug.SCAN_PRO:
      if (size.isMdOrLower) {
        return require('public/images/plans/scan-pro-point-right-mobile.png')
      }
      return require('public/images/plans/scan-pro-point-right-desktop.png')
    default:
      return require('public/images/hero/homepage-collect-plan-desktop.png')
  }
}

export function getPlanSummaryImage(slug: PlanSlug) {
  switch (slug) {
    case PlanSlug.COLLECT:
      return require('public/images/hero/homepage-collect-plan-desktop.png')
    case PlanSlug.FORWARD:
      return require('public/images/hero/homepage-forward-plan-desktop.png')
    case PlanSlug.MEETINGS:
      return require('public/images/hero/homepage-meetings-plan-desktop.png')
    case PlanSlug.SCAN_PRO:
      return require('public/images/hero/homepage-scan-pro-plan-desktop.png')
    case PlanSlug.SCAN_LITE:
      return require('public/images/hero/homepage-scan-lite-plan-desktop.png')
    case PlanSlug.PHONE:
      return require('public/images/hero/homepage-phone-plan-desktop.png')
  }
}
