import { Format } from './format'

// PLAN_PRICES_GENERATED_BY_SCRIPT

const prices = {
  "daily": {
    "lowest-fair": 49
  },
  "hourly": {
    "meeting-rooms": 3500
  },
  "monthly": {
    "hot-desk-coworking": 40000,
    "private-office-8-10": 288000,
    "virtual-office-collect": 1800,
    "virtual-office-forward": 2500,
    "virtual-office-scan-pro": 3500,
    "virtual-office-scan-lite": 2000,
    "meeting-rooms": 3500,
    "virtual-office-phone": 699
  },
  "yearly": {
    "virtual-office-collect": 18000,
    "virtual-office-forward": 25000,
    "virtual-office-scan-pro": 35000,
    "virtual-office-scan-lite": 20000,
    "meeting-rooms": 35000,
    "virtual-office-phone": 6990
  }
}

      const getFormattedPrices = (
  duration: 'monthly' | 'yearly' | 'hourly' | 'daily',
) => {
  return Object.entries(prices[duration]).reduce(
    (formattedPrices, [plan, price]) => {
      formattedPrices[plan] = Format.money(price)
      return formattedPrices
    },
    {} as { [key: string]: string },
  )
}

export const planPrice = {
  daily: getFormattedPrices('daily'),
  hourly: getFormattedPrices('hourly'),
  monthly: getFormattedPrices('monthly'),
  monthlyOnYearlyPlan: (plan: string) => {
    const yearlyPrice =
      Number(planPrice.yearly[plan].replace(/[^0-9.-]+/g, '')) * 100

    return Format.money(yearlyPrice / 12)
  },
  yearly: getFormattedPrices('yearly'),
}

      