import {
  BreadcrumbJsonLd,
  NextSeo,
  NextSeoProps,
  OrganizationJsonLd,
  SiteLinksSearchBoxJsonLd,
} from 'next-seo'
import { useRouter } from 'next/router'
import { ReactFC } from 'common/types/ReactFC'
import { seoStrings } from 'common/strings/seo-strings'
import { ItemListElements } from 'next-seo/lib/types'
import Head from 'next/head'
import { Constants } from 'common/utils'

const CURRENT_URL = process.env.SITE_URL

const getCanonical = (path: string) => {
  const fullURL = new URL(path, CURRENT_URL)
  return `${fullURL.origin}${fullURL.pathname}`
}

export type SeoContainerProps = {
  seoProps?: NextSeoProps
  noIndex?: boolean
  breadcrumb: ItemListElements[]
}
export const SeoContainer: ReactFC<SeoContainerProps> = ({
  breadcrumb,
  children,
  noIndex,
  seoProps,
}) => {
  const { asPath, locale } = useRouter()

  if (!process.env.prod && process.env.NODE_ENV !== 'development') {
    return (
      <>
        <Head>
          <title>{seoProps?.title}</title>
        </Head>
        {children}
      </>
    )
  }
  return (
    <>
      <Head>
        <link rel='canonical' href={getCanonical(asPath)} />
      </Head>
      <BreadcrumbJsonLd itemListElements={breadcrumb} />
      <SiteLinksSearchBoxJsonLd
        name={seoStrings.appSiteName}
        url={getCanonical(`/`)}
        potentialActions={[
          {
            queryInput: 'search_term_string',
            target: `${process.env.SITE_URL}/blog/search?q`,
          },
        ]}
      />
      <NextSeo
        {...seoProps}
        noindex={!!noIndex}
        nofollow={!!noIndex}
        openGraph={{
          description: seoProps?.description,
          images: [
            {
              alt: seoStrings.appOGLogoAlt,
              height: 226,
              url: seoStrings.appOGLogo,
              width: 400,
            },
          ],
          locale,
          site_name: seoStrings.appSiteName,
          title: seoProps?.title,
          type: 'website',
          ...(seoProps?.openGraph || {}),
        }}
        twitter={{
          cardType: 'summary',
          handle: Constants.companyInformation.twitter_handle,
          site: Constants.companyInformation.twitter_handle,
          ...(seoProps?.twitter || {}),
        }}
      />
      <OrganizationJsonLd
        name={seoStrings.organisationName}
        url={'https://www.hoxtonmix.com'}
        telephone={'+44 20 3475 3374'}
        address={{
          addressCountry: 'UK',
          addressLocality: 'London',
          addressRegion: 'Greater London',
          postalCode: 'EC2A 4NE',
          streetAddress: '3rd Floor, 86-90 Paul Street',
          type: 'PostalAddress',
        }}
        logo={'https://www.hoxtonmix.com/organisation.png'}
        sameAs={[
          'https://twitter.com/hoxtonmix',
          'https://www.facebook.com/hoxtonmix/',
          'https://www.linkedin.com/company/the-hoxton-mix/',
          'https://www.youtube.com/c/Hoxtonmix',
          'https://www.instagram.com/hoxtonmix/',
        ]}
      />
      {children}
    </>
  )
}

SeoContainer.displayName = 'SeoContainer'
export default SeoContainer
